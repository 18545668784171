<script>
import { getDate, getShortMonth } from "~/modules/helpers/day";
import Swiper from "swiper";

export default {
  props: {
    news: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { getDate, getShortMonth };
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    isSlider() {
      return true;
    },
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      slidesPerView: 2,
      speed: 400,
      slidesPerColumnFill: "row",
      spaceBetween: 28,
      initialSlide: 0,
      // autoplay: true,
      delay: 1000,
      loop: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          allowTouchMove: false,
        },
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 2,
        },
      },
    });
  },
};
</script>

<template>
  <r-section title="Новости" :link="{ to: '/news', title: 'Все новости' }">
    <div class="w-full" v-if="isSlider">
      <div class="relative swiper-container" ref="swiper">
        <div class="swiper-wrapper">
          <div
            class="
              swiper-slide
              h-50
              xl:h-80
              relative
              rounded-xl
              overflow-hidden
              bg-black
            "
            v-for="item in news"
            :key="item._id"
          >
            <nuxt-link :to="`/news/${item._id}`">
              <img
                loading="lazy"
                :src="`https://ucarecdn.com/${item.img.fileKey}/-/format/auto/-/quality/smart/image.png`"
                class="
                  w-full
                  h-full
                  object-cover
                  transform-gpu
                  transition-all
                  duration-300
                  opacity-80
                  hover:opacity-60
                "
              />
              <div
                class="
                  absolute
                  bottom-0
                  left-0
                  right-0
                  grid grid-cols-12
                  z-10
                  h-32
                "
              >
                <div class="relative w-40 col-start-1 col-end-3">
                  <div
                    class="
                      absolute
                      rounded-4xl
                      bg-ternary
                      -z-1
                      transform-gpu
                      rotate-26
                      hidden
                      xl:block
                    "
                    style="width: 194px; height: 180px; left: -60px"
                  />
                  <div
                    class="
                      absolute
                      z-10
                      top-0
                      bottom-0
                      right-2
                      left-0
                      pr-2
                      hidden
                      xl:flex
                      flex-col
                      justify-center
                    "
                  >
                    <div
                      class="
                        mx-auto
                        font-sans font-medium
                        text-4xl
                        uppercase
                        text-white
                      "
                    >
                      {{ getDate(item.createdAt) }}
                    </div>
                    <div class="mx-auto">
                      <img
                        loading="lazy"
                        src="/line.svg"
                        style="height: 2px"
                      />
                    </div>
                    <div
                      class="mx-auto font-sans font-medium text-xl text-white"
                    >
                      {{ getShortMonth(item.createdAt) }}
                    </div>
                  </div>
                </div>
                <div
                  class="
                    relative
                    p-4
                    flex
                    w-auto
                    col-start-1
                    xl:col-start-4
                    col-end-12
                  "
                >
                  <p
                    class="cards__news__text text-white mt-auto text-lg2"
                    v-html="item.name"
                  ></p>
                </div>
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
      <div
        class="
          slide-next
          absolute
          -left-12
          top-40
          z-10
          cursor-pointer
          hidden
          xl:block
        "
        @click="swiper.slidePrev()"
      >
        <img
          loading="lazy"
          src="/arrow-l.svg"
          alt=""
        />
      </div>
      <div
        loading="lazy"
        class="
          slide-next
          absolute
          -right-12
          top-40
          transform
          rotate-180
          z-10
          cursor-pointer
          hidden
          xl:block
        "
        @click="swiper.slideNext()"
      >
        <img
          src="/arrow-l.svg"
          alt=""
        />
      </div>
    </div>
  </r-section>
</template>

<style scoped>
img.object-cover {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  transition: 0.3s;
  max-width: 100vw;
}
img.object-cover:hover {
  position: absolute;
  width: 110%;
  height: 110%;
  min-width: 110%;
  min-height: 110%;
  left: -5%;
  top: -5%;
}
</style>
